<template>
  <div style="height: 100%; width: 100%; ">
    <search-table
        ref="searchTable"
        labelWidth="70px"
        :loading="listLoading"
        :searchFields="searchFields"
        :autoBtnList="autoBtnList"
        :colSize="colSize"
        :total="total"
        :tableData="dataList"
        :tableColumns="tableColumns"
        :baseColumns="baseColumns"
        :sortFields="sortFields"
        :checkboxColumn="false"
        :hasPagination="true"
        :showFooter="true"
        :showSetting="false"
        :showColumnSearch="false"
        :showSortable="false"
        :showSortSetting="false"
        :tableSummary="tableSummary"
        :proxyParams="proxyParams"
        :optionColumn="optionColumn"
        :selectTableSummary="selectTableSummary"
        @saveTableField="saveTableField"
        @saveSearchField="saveSearchField"
        @saveSortField="saveSortField"
        @getTableData="queryList"
    >
    </search-table>
    <el-dialog title="导入" :visible.sync="open" class="" append-to-body>
      <el-upload
          class="upload-demo"
          ref="upload"
          :multiple="false"
          :show-file-list="true"
          accept='.xls,.xlsx'
          :action="uploadUrl"
          :headers="uploadHeaders"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :on-change='onChange'
          :file-list="[]"
          :on-success="uploadFileSuccess"
          :on-error="uploadFileError"
          :limit="1"
          :auto-upload="false"
      >
        <el-button size="small" type="primary">浏览文件</el-button>
      </el-upload>
      <el-button style="margin-left: 200px" @click="setUpload"  type="primary">上传</el-button>
      <el-button  @click="close">关闭</el-button>
    </el-dialog>

  </div>
</template>
<script>
import { queryList } from '@/api/provinceBill'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/provinceBill/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'
import store from "@/store";
import {getToken} from "@/utils/auth";

export default {
  components: {
    SearchTable,
  },
  mixins: [column],

  data: function () {
    return {
      total: 0,
      summary: {},
      dataList: [],
      listLoading: false,
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      path: '', // 更新字段名显示接口路径
      fileList: [],
      //弹窗
      open:false,
      uploadUrl: '/system/provinceTransportRecords/upload', // admin-gov
      uploadData: {},
      uploadHeaders: {
        token: getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      loading:null
    }
  },
  created() {
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
    console.info("this.tableColumns", this.tableColumns)
  },

  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  activated() {
    this.queryList()
    console.info("this.tableColumns22", this.tableColumns)
  },
  methods: {

    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 选取完文件触发事件
    onChange(a, fileList) {
      this.fileList = fileList;
    },
    setUpload() {
      var _this = this;
      //如果没有选择文件则不允许点击,并给出提示选择文件后点击上传按钮
      if (_this.fileList == '') {
        this.$notify.info({
          title: '消息',
          message: '请先选择 [浏览文件] 后在点击上传!'
        });
      } else {
        //创建formData对象
        this.loading = this.$loading({
          lock: true,//lock的修改符--默认是false
          text: '上传中',//显示在加载图标下方的加载文案
          spinner: 'el-icon-loading',//自定义加载图标类名
          background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
          target: document.querySelector('#table')//loadin覆盖的dom元素节点
        });
       this.$refs.upload.submit()
      }
    },
    uploadFileSuccess() {
      this.$message.success(`上传成功`)
      this.close()
    },
    uploadFileError(err) {
      const res = JSON.parse(err.message)
      this.$message.error(res.message || `上传失败`)
      this.close()
    },
      //上传成功后关闭弹窗并调用查询方法刷新页面数据
      close(){
        var _this=this;
        _this.open=false
        _this.fileList = []
        if(_this.loading){
          _this.loading.close()
        }
        _this.queryList();
      },

    // 查询
    async queryList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params['role'] = this.$store.getters.userType
      this.listLoading = true
      this.baseColumns = config.baseColumn(this)
      const res = await queryList({ ...params })

      if (res.code === 0) {
        this.dataList = res.data.items
        this.total = res.data.total * 1
      }
      this.searchFields.forEach(item => {
        if (item.field === 'name') {
          item.selectOptions = this.bossProList
        }
      })
      // console.info(this.dataList)
      // console.info(this.dataList.length)
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
          .filter(v => v.sortable)
          .reduce((prev, item) => {
            // if(item.sortList) prev = prev.concat(item.sortList)
            prev.push({
              title: `${item.title}升序`,
              field: `${item.field}-asc`
            })
            prev.push({
              title: `${item.title}降序`,
              field: `${item.field}-desc`
            })
            return prev
          }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    importExcelHandler(){
      this.open = true
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {},
  }
}
</script>

<style scoped>
/deep/ .video-container-wrap  {
  position: fixed !important;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

</style>
