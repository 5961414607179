const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return [
    // { name: '批量修改', btnFn: vm.batchEditHandler, plain: true, icon: 'el-icon-edit' },
    // { name: '批量备注', btnFn: vm.orderRemarkHandle, plain: true, icon: 'el-icon-finished' },
    { name: '导入', btnFn: vm.importExcelHandler, plain: true, icon: 'el-icon-upload' }
    // { name: '恢复工单', btnFn: vm.recoverOrderHandler, plain: true, icon: 'el-icon-refresh-left' }
  ]
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      "keyId": 5,
      "title": "车牌号",
      "field": "licensePlate",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 12,
      "title": "出场工地",
      "field": "departureSite",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 21,
      "title": "入场工地",
      "field": "entrySite",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      "keyId": 1,
      "title": "联单类型",
      "field": "receiptType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 2,
      "title": "状态",
      "field": "status",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 3,
      "title": "运输企业",
      "field": "transportCompany",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 4,
      "title": "运输企业统一信用代码",
      "field": "transportCompanyCreditCode",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 5,
      "title": "车牌号",
      "field": "licensePlate",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 6,
      "title": "驾驶员",
      "field": "driver",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 7,
      "title": "驾驶员联系电话",
      "field": "driverPhone",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 8,
      "title": "运输工具",
      "field": "transportTool",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 9,
      "title": "发运人",
      "field": "shipper",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 10,
      "title": "发运人联系电话",
      "field": "shipperPhone",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 11,
      "title": "出场时间",
      "field": "departureTime",
      "width": 150,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 12,
      "title": "出场工地",
      "field": "departureSite",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 13,
      "title": "出场工地类型",
      "field": "departureSiteType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 14,
      "title": "出场工地地址",
      "field": "departureSiteAddress",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 15,
      "title": "出场工地应急电话",
      "field": "departureSiteEmergencyPhone",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 16,
      "title": "出场工地区域",
      "field": "departureSiteArea",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 17,
      "title": "责任单位名称(施工单位)",
      "field": "responsibleUnitName",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 18,
      "title": "责任单位统一社会信用代码",
      "field": "responsibleUnitCreditCode",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 19,
      "title": "接收人",
      "field": "receiver",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 20,
      "title": "入场时间",
      "field": "entryTime",
      "width": 150,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 21,
      "title": "入场工地",
      "field": "entrySite",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 22,
      "title": "入场工地类型",
      "field": "entrySiteType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 23,
      "title": "入场工地地址",
      "field": "entrySiteAddress",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 24,
      "title": "入场经办人联系电话",
      "field": "entryHandlerPhone",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 25,
      "title": "入场工地区域",
      "field": "entrySiteArea",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 26,
      "title": "垃圾种类",
      "field": "wasteType",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 27,
      "title": "接收意见",
      "field": "receptionOpinion",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 28,
      "title": "接收信息备注",
      "field": "receptionInfoNotes",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 29,
      "title": "工程渣土量（吨）",
      "field": "constructionDirtAmount",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 30,
      "title": "废弃泥浆量（吨）",
      "field": "wasteSlurryAmount",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 31,
      "title": "工程垃圾量（吨）",
      "field": "constructionWasteAmount",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 32,
      "title": "拆除垃圾量（吨）",
      "field": "demolitionWasteAmount",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 33,
      "title": "装修垃圾量（吨）",
      "field": "renovationWasteAmount",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 34,
      "title": "处置方式",
      "field": "disposalMethod",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 35,
      "title": "联运车辆",
      "field": "coTransportVehicle",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 36,
      "title": "联运地点",
      "field": "coTransportLocation",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 37,
      "title": "联运企业",
      "field": "coTransportCompany",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 38,
      "title": "联运企业统一信用代码",
      "field": "coTransportCompanyCreditCode",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 39,
      "title": "联运时间",
      "field": "coTransportTime",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 40,
      "title": "中转码头",
      "field": "transshipmentTerminal",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 41,
      "title": "中转码头区域",
      "field": "transshipmentTerminalArea",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 42,
      "title": "中转船舶",
      "field": "transshipmentVessel",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 43,
      "title": "中转码头转入时间",
      "field": "transshipmentTerminalEntryTime",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 44,
      "title": "是否跨省运输",
      "field": "interprovincialTransport",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 45,
      "title": "交通方式",
      "field": "transportMode",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 46,
      "title": "接收量",
      "field": "receptionVolume",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 47,
      "title": "联单编号",
      "field": "receiptNumber",
      "width": 150,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    }
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
